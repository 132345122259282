import { IH_OMS_API_URL, GLOBAL_ERROR, GLOBAL_SUCCESS, PROJECT, CAS_API_URL } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export function setProject(data) {
  return (dispatch) => {
    dispatch({ type: PROJECT, payload: data });
    return { status: "successStatus", message: "" };
  };
}

export function searchProjects(data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function createBillingProject(crId, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/requirements/${crId}/billing_projects`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "New project created successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getProject(id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: PROJECT,
          payload: response.data.data.project,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function postMember(org_id, data) {
  console.log(org_id);
  console.log(data);
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/invite`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Member is invited",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchUsers(org_id, data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function deleteMembership(org_id, user_id, id) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/${user_id}/memberships/${id}`;
  const request = customHttp({
    url,
    method: "delete",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function addRoleInMember(org_id, data) {
  const url = `${CAS_API_URL}/client/api/v1/orgs/${org_id}/users/invite`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Member is updated",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchExecutions(id, access_type, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${id}/executions/${access_type}/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getExecution(id) {
  const url = `${IH_OMS_API_URL}/execution/api/v1/executions/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function addLead(execution_id, role_id, list_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/leads`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function cloneLead(execution_id, role_id, list_view_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/leads/${lead_id}/clone`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Cloned Successfully" });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function deleteLead(execution_id, project_role_id, list_view_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "delete",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function filterView(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/project_roles/${project_role_id}/filter_view`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function leadsAnalyzeSearch(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/project_roles/${project_role_id}/leads/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function searchLeadAttributes(id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${id}/lead_attributes/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function listViewsSearch(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function searchScreens(data, project_id, role_id, data_view_id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${role_id}/data_views/${data_view_id}/screens/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchDataViews(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/data_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function patchLead(execution_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/screens/${screen_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchNextScreen(execution_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/leads/${lead_id}/screens/${screen_id}/next`;
  const request = customHttp({
    url,
    method: "get",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateStatus(execution_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/screens/${screen_id}/leads/${lead_id}/status`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function bulkStatusUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_status_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload:
            "Your request to change status of the tasks has been taken successfully. Please refresh after sometime to see the updated tasks or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getLead(execution_id, role_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchLeads(execution_id, role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchProjectLevelExecutions(id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${id}/executions/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchSummaryViews(execution_id, role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/summary_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function summaryViewAnalyze(execution_id, project_role_id, access_type, tab_id, summary_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/project_owner/executions/${execution_id}/project_roles/${project_role_id}/tabs/${tab_id}/summary_views/${summary_view_id}/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function bulkFieldsUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload:
            "Your request to update fields has been taken successfully. Please refresh after sometime to see the updated tasks or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateLeadAttribute(execution_id, list_view_id, lead_id, lead_attribute_uid, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/list_views/${list_view_id}/leads/${lead_id}/attributes/${lead_attribute_uid}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function bulkLeadsCreate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/csv_create`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload:
            "Your request to create tasks has been taken successfully. Please refresh after sometime to see the tasks or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function bulkLeadsUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/csv_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload:
            "Your request to update tasks has been taken successfully. Please refresh after sometime to see the updated tasks or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function bulkLeadsAllocate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_allocate`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload:
            "Your request to allocate tasks has been taken successfully. Please refresh after sometime to see the allocated tasks or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function exportAllLeads(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/leads/export_all`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Your request to export has been taken successfully. Please check the reports section for the exported leads." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function exportLeads(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/export`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Your request to export has been taken successfully. Please check the reports section for the exported leads." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchProjectRoleGroups(data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/project_role_groups/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function searchRoles(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function asyncReportsSearch(execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/background_requests/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });

          return { status: "errorStatus", message: error };
        }
      });
}

export function searchProjectReports(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function createProjectReport(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Created Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateProjectReport(project_id, project_report_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/${project_report_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Updated Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function deleteProjectReport(project_id, project_report_id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/${project_report_id}`;
  const request = customHttp({
    url,
    method: "delete",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Deleted Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function infoViewSearch(project_role_id, execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/info_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getWorkTypes(data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/work_types/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getIndustryTypes(data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/industry_types/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getSkillTypes(data) {
  const url = `${IH_OMS_API_URL}/clients/api/v1/skill_types/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}
