import React, { Component } from "react";
import { connect } from "react-redux";
import { logout, validateToken } from "../../../actions";
import history from "../../../history";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { DOMAIN, LOGIN, LOGO_NAME_WHITE } from "../../../constant";
import Cookies from "universal-cookie";
import { requestSaaSAccess } from "../../../actions/action_saas_members";
import { withGetScreen } from "../../../utils/withGetScreen";

const cookies = new Cookies();
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    const { currentUser, match, location } = this.props;
    const bound_saas_org_id = currentUser && currentUser.bound_saas_org_id;
    const domain = match.params.domain;
    const org = currentUser && currentUser.memberships.find((org) => org.domain === domain);
    const isClient = currentUser && currentUser.roles.includes("int-client");
    this.hideSaasCTA = location.pathname.endsWith("saas_success") || !org || !isClient || !!bound_saas_org_id;
  }

  callLogout = () => {
    this.props.logout().then(() => {
      history.push("/");
    });
  };

  requestSaas = () => {
    const domain = this.props.match.params.domain;
    const org = this.props.currentUser.memberships.filter((org) => org.domain === domain)[0] || {};
    this.props.requestSaaSAccess({ org_id: org.org_id, id: this.props.currentUser.id, domain }).then((returnValue) => {
      if (returnValue && returnValue.status === "successStatus") {
        this.props.validateToken().then((returnValue) => {
          if (returnValue && returnValue.status === "successStatus") {
            cookies.set("currentUser", JSON.stringify({ data: returnValue.message.data }), { path: "/", domain: DOMAIN, maxAge: 86400 });
            this.props.setAuthData(returnValue.message);
            history.push(`/${domain}/saas_success`);
            // window.open(`/${domain}/saas_success`, "_self");
          }
        });
      }
    });
  };

  render() {
    const { currentUser } = this.props;
    const roles = (currentUser && currentUser.roles) || [];
    if (currentUser) {
      return !this.props.isMobile() ? (
        <div>
          <div className="full-width bg-blue fixed-top">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="row center-y">
                  <div className="col-6 col-md-9 center-y">
                    <div className="d-inline-block pointer relative float-left pr-5">
                      <a href="/">
                        <img src={LOGO_NAME_WHITE} className="full-width full-height header-img relative" alt="awign.com" />
                        {roles.includes("int-ce") && <span className="text-white ml-4">CE Team</span>}
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <Navbar.Toggle className="justify-content-end float-right" id="basic-navbar-nav">
                      <Nav className="mr-right">
                        <NavDropdown
                          title={
                            <span>
                              <img
                                className="header-icon-profile"
                                onMouseOver={(e) => (e.currentTarget.src = "https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/topbar/ic_profile_blue.svg")}
                                onMouseLeave={(e) => (e.currentTarget.src = "https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_profile.svg")}
                                src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_profile.svg"
                                alt=""
                              />
                              <small className="text-white">{this.props.currentUser.name}</small>
                            </span>
                          }
                          id="profile-nav-dropdown"
                          className="left-menu with-header"
                        >
                          {/* {this.hideSaasCTA || (
                            <NavDropdown.Item onClick={() => this.requestSaas()} eventKey={3.1}>
                              Request SaaS Access
                            </NavDropdown.Item>
                          )} */}
                          <NavDropdown.Divider className="my-0" />
                          <NavDropdown.Item onClick={() => this.callLogout()} eventKey={3.2}>
                            <img className="dropdown-icons" src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_logout.svg" alt="" /> Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </Navbar.Toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="mobile-layout-wrapper"
          onClick={() => {
            history.push("/");
          }}
        >
          Data
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.login.currentUser,
    deviceType: state.common.deviceType,
    project: state.projects.project,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setAuthData: (data) => {
    dispatch({ type: LOGIN, payload: data.data.data.user });
  },
  validateToken: () => dispatch(validateToken()),
  requestSaaSAccess: (props) => dispatch(requestSaaSAccess(props)),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Header));
